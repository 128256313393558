import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Collapse,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';

const categories = [
  'Electronics',
  'Automotive',
  'Food and Beverage',
  'Fashion',
  'Personal Care',
  'E-commerce',
  'Social Media',
  'Streaming Services'
];

const SentimentAnalyzer = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [openTable, setOpenTable] = useState(true);

  const getScoreColor = (score) => {
    if (score >= 80) return '#4caf50'; // Green
    if (score >= 60) return '#8bc34a'; // Light Green
    if (score >= 40) return '#ffc107'; // Yellow
    if (score >= 20) return '#ff9800'; // Orange
    return '#f44336'; // Red
  };

  const handleScoreClick = (brand, source, score, response) => {
    // You can implement a modal or tooltip here to show the detailed response
    console.log(`${source} analysis for ${brand}: ${score}`);
    console.log('Detailed response:', response);
    // For now, just alert the response
    alert(`${source} analysis for ${brand}:\n\n${response}`);
  };

  const handleAnalysis = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/sentiment-analysis`,
        { searchTerm },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true
        }
      );
      setResults(response.data.results);
    } catch (err) {
      console.error('Analysis error:', err);
      setError(err.response?.data?.message || 'Error performing analysis');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/logout`,
        {},  // empty body
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      localStorage.removeItem('user');
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button 
          variant="outlined" 
          onClick={handleLogout}
          sx={{ color: 'error.main', borderColor: 'error.main' }}
        >
          Logout
        </Button>
      </Box>
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
        Brand Sentiment Analyzer
      </Typography>
      
      <Box sx={{ 
        display: 'flex', 
        gap: 2, 
        marginBottom: 3,
        alignItems: 'center' 
      }}>
        <FormControl fullWidth>
          <InputLabel id="category-select-label">Select Category</InputLabel>
          <Select
            labelId="category-select-label"
            value={searchTerm}
            label="Select Category"
            onChange={(e) => setSearchTerm(e.target.value)}
          >
            {categories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={handleAnalysis}
          disabled={loading || !searchTerm}
          sx={{ 
            height: 56,
            minWidth: 120,
            backgroundColor: '#1976d2',
            '&:hover': {
              backgroundColor: '#115293'
            }
          }}
        >
          Analyze
        </Button>
      </Box>

      {error && (
        <Typography color="error" sx={{ marginBottom: 2 }}>
          {error}
        </Typography>
      )}

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {results.length > 0 && (
        <Box sx={{ margin: '0 auto', maxWidth: 800 }}>
          <Paper elevation={3}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
              <Typography variant="h6">Results</Typography>
              <IconButton onClick={() => setOpenTable(!openTable)}>
                {openTable ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Box>
            <Collapse in={openTable}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><Typography variant="subtitle1">Brand</Typography></TableCell>
                      <TableCell align="right"><Typography variant="subtitle1">OpenAI Score</Typography></TableCell>
                      <TableCell align="right"><Typography variant="subtitle1">Anthropic Score</Typography></TableCell>
                      <TableCell align="right"><Typography variant="subtitle1" fontWeight="bold">Average Score</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {results.map((result, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <Typography>{result.producer}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            onClick={() => handleScoreClick(
                              result.producer, 
                              'OpenAI', 
                              result.openaiScore, 
                              result.openaiResponse
                            )}
                            style={{ color: getScoreColor(result.openaiScore) }}
                          >
                            {result.openaiScore}
                          </Button>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            onClick={() => handleScoreClick(
                              result.producer, 
                              'Anthropic', 
                              result.anthropicScore, 
                              result.anthropicResponse
                            )}
                            style={{ color: getScoreColor(result.anthropicScore) }}
                          >
                            {result.anthropicScore}
                          </Button>
                        </TableCell>
                        <TableCell align="right">
                          <Typography fontWeight="bold" style={{ color: getScoreColor(result.averageScore) }}>
                            {result.averageScore}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default SentimentAnalyzer;
