import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import SentimentAnalyzer from './components/SentimentAnalyzer';
import Register from './components/Register';

// Protected Route component
const ProtectedRoute = ({ children }) => {
  // Check if user is logged in by looking for user data in localStorage
  const user = JSON.parse(localStorage.getItem('user'));
  
  if (!user) {
    // Redirect to login if not logged in
    return <Navigate to="/" replace />;
  }

  return children;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Navigate to="/" replace />} />
        <Route path="/register" element={<Register />} />
        <Route 
          path="/analyze" 
          element={
            <ProtectedRoute>
              <SentimentAnalyzer />
            </ProtectedRoute>
          } 
        />
      </Routes>
    </Router>
  );
}

export default App;
